import React, { PropsWithChildren, useState } from 'react'
import TriggerIcon from './base/icon.svg'
import { Panel, Root, TriggerButton } from './base/styled-components'
import { WebViewConfig, WebViewConfigProvider } from './providers'

type Props = PropsWithChildren<{
  webViewConfig?: WebViewConfig
}>

function DevTools(props: Props) {
  const { webViewConfig, children } = props
  const [isOpen, setOpen] = useState(false)

  return (
    <WebViewConfigProvider webViewConfig={webViewConfig}>
      <Root>
        <Panel style={{ display: isOpen ? 'block' : 'none' }}>
          {children}
        </Panel>
        <TriggerButton
          type="button"
          onClick={() => setOpen(!isOpen)}
        >
          <img src={TriggerIcon} width="25" height="24" alt="" />
        </TriggerButton>
      </Root>
    </WebViewConfigProvider>
  )
}

export { DevTools }
