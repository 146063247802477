import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/axios";

/**
 * 로그인 API
 * @param params
 * @param config
 */
export function storeLogin(params: LoginParams, config: AxiosRequestConfig = {}): AxiosPromise<LoginResData> {
    const { id } = params
    const { params: configParams, ...restConfig } = config
    return axiosClient.post<LoginResData>('/api/auth/store-login',
        {id, ...configParams},
        {...restConfig}
    )
}

/**
 * - id : 상점 아이디
 */
export interface LoginParams {
    id: string;
}

/**
 * access_token: 엑세스 토큰
 */
export interface LoginResData {
    message: {
        access_token: string | null; // 엑세스 토큰
    },
}
