const PREFIX = 'TMAP-VALET-STORE-REACT:'
const ACCESS_TOKEN = 'ACCESS_TOKEN'

export const storage = {
  setItem: (key: string, value: any) => {
    localStorage.setItem(`${PREFIX}${key}`, value)
  },
  getItem: (key: string) => {
    return localStorage.getItem(`${PREFIX}${key}`)
  },
  removeItem: (key: string) => {
    localStorage.removeItem(`${PREFIX}${key}`)
  },
  setAccessToken: (accessToken: string) => {
    storage.setItem(ACCESS_TOKEN, accessToken)
  },
  getAccessToken: () => {
    return storage.getItem(ACCESS_TOKEN)
  },
  removeAccessToken: () => {
    storage.removeItem(ACCESS_TOKEN)
  },
}
