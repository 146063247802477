import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/axios";

/**
 * 출차요청 API
 * @param params
 * @param config
 */
export function exitRequest(params: Params, config: AxiosRequestConfig = {}): AxiosPromise {
    const { id } = params
    const { params: configParams, ...restConfig } = config
    return axiosClient.patch<ResponseData>('/api/valet-parking/exitRequest',
        {
            id,
            ...configParams,
        },
        {
            ...restConfig,
        },
    )
}

/**
 * id:     valet-parking id, PK
 */
interface Params {
    id: number;
}

interface ResponseData {
    message: {}, // FE 에서는 응답이 정상인지만 확인(200), DATA 는 활용 X
}
