import React, {lazy, Suspense} from 'react'
import {Container, GlobalLoading, ModalContainer, ToastContainer} from './components'
import {RoutePath} from "@/pages/routes";
import {selectAppAccessToken, selectAppIsMobile} from "@/store/app";
import {useSelector} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom";
import useWindowResizeEvent from "@/utils/use-window-resize-event";

const Login = lazy(() => import('@/pages/login/Login'))
const Main = lazy(() => import('@/pages/root/Main'))
const Parked = lazy(() => import('@/pages/parked/Parked'))
const Exit = lazy(() => import('@/pages/exit/Exit'))
const Sample = lazy(() => import('@/pages/sample/Sample'))
const Error = lazy(() => import('@/pages/error/Error'))
const Settings = lazy(() => import('@/pages/settings/Settings'))

function App() {
    const accessToken = useSelector(selectAppAccessToken)
    const isMobile = useSelector(selectAppIsMobile)
    useWindowResizeEvent()

    return (
        <>
            {<GlobalLoading/>}
            <ModalContainer/>
            <ToastContainer/>
            <Container className={isMobile? 'type_mobile': ''}>
              <Suspense fallback="">
                  <Routes>
                      {
                          accessToken ?
                              (
                                  <>
                                      <Route path={RoutePath.Main} element={<Main/>}/>
                                      <Route path={RoutePath.Settings} element={<Settings/>}/>
                                      {isMobile && (<Route path={RoutePath.History} element={<Main/>}/>)}
                                      <Route path={RoutePath.History} element={<Main/>}/>
                                      <Route path={RoutePath.Parked} element={<Parked/>}/>
                                      <Route path={RoutePath.Exit} element={<Exit/>}/>
                                      <Route path={RoutePath.Sample} element={<Sample/>}/>
                                      <Route path={RoutePath.Error} element={<Error/>}/>
                                      <Route
                                          path="*"
                                          element={<Navigate to={RoutePath.Main} replace />}
                                      />
                                  </>
                              ) :
                              (
                                  <>
                                      <Route path={RoutePath.Login} element={<Login/>}/>
                                      <Route path={RoutePath.Error} element={<Error/>}/>
                                      <Route
                                          path="*"
                                          element={<Navigate to={RoutePath.Login} replace />}
                                      />
                                  </>
                              )
                      }
                  </Routes>

              </Suspense>
            </Container>
        </>
    )
}

export default App
