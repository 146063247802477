import React, { HTMLAttributes } from 'react'
import Circular from '../../assets/images/circular.svg'

interface Props extends HTMLAttributes<HTMLDivElement> {}

function Loading(props: Props) {
  const { className, ...attrs } = props
  return (
    <div className={`loading_indicator ${className}`} {...attrs}>
      <img src={Circular} alt="" />
    </div>
  )
}

export { Loading }
