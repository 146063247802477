import React from 'react';
import {useToast} from './hooks';
import {Toast} from "@/components/toast/Toast";
import { useSelector } from 'react-redux';
import { selectAppIsMobile } from '@/store/app';
import cx from 'classnames';

export const ToastContainer = () => {
    const { queue } = useToast();
    const isMobile = useSelector(selectAppIsMobile)

    return (
        <div className={cx('toast_wrap', {type_mobile: isMobile})}>
            {queue.map((toast, index) => (
                <Toast key={toast.id} message={toast.message} active={toast.active} />
            ))}
        </div>
    )
}