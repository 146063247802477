import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {LoginInfoData} from '@/axios';

interface LoginInfoState {
  item: LoginInfoData
}
interface Actions extends SliceCaseReducers<LoginInfoState> {
  setLoginInfo(state: LoginInfoState, action: PayloadAction<LoginInfoData>): void;
}

const initialState: LoginInfoState = {
  item: {
    id: 0,
    name: '',
    contact: '',
    address: '',
    defaultPrice: 0,
    defaultPriceUnitTime: 0,
    hasExtraCharge: false,
    extraCharge: 0,
    extraChargeUnitTime: 0,
    eventPrice: 0,
    isEvent: false,
    price: 0,
    isActive: false,
    isDeleted: false,
    createdAt: '', // 2022-08-03T03:01:21.111Z
    updatedAt: '', // 2022-08-03T03:01:21.111Z
    zoneId: 0,
    zone: {
      id: 0,
    },
  },
}

export const loginInfoSlice = createSlice<LoginInfoState, Actions>({
  name: 'app',
  initialState,
  reducers: {
    setLoginInfo(state, action) {
      const { payload } = action
      state.item = payload
    },
  },
})

export const {
  setLoginInfo,
} = loginInfoSlice.actions

export const selectLoginInfo = (state: RootState) => state.loginInfo.item