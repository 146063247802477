import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/axios";

/**
 * 입/출차 요청 현황 리스트 API
 * @param params
 * @param config
 */
export function getHistoryByStoreId(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        take = 30,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/valet-parking/getHistoryByStoreId', {
        ...restConfig,
        params: {
            take,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * page: 페이지번호
 * take: 페이지크기
 */
interface Params {
    page: number;
    take?: number;
}


interface ResponseData {
    message: {
        rows: StoreHistoryData[]
    };
    totalCount: number;
}

/**
 * id:              store id, PK
 * plateNumber:     차량 번호
 * parkingStatus:   주차 상태 (0: 입차 완료, 1: 출차 완료, 2: 입차요청, 3: 출차요청, 4: 입차접수, 5: 출차접수, 6:입차진행중, 7: 출차진행중)
 */
export interface StoreHistoryData {
    id: number;
    plateNumber: string;
    parkingStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
}
