import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/axios";

/**
 * 입차 현황 리스트(출차 요청이 가능한 리스트) API
 * @param params
 * @param config
 */
export function getExitByPlateNumber(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        plateNumber,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>(`/api/valet-parking/${plateNumber}`, {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 * plateNumber: 출차 요청 번호
 */
interface Params {
    plateNumber: string;
}

interface ResponseData {
    message: StoreExitData[];
}

/**
 * id: valet-parking id, PK
 */
export interface StoreExitData {
    id: number;
    plateNumber: string;
}
