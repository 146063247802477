import { useCallback } from 'react'
import { Alert, AlertModalProps } from '../Alert'
import { useModalManager } from './use-modal-manager'

export function useAlertModal() {
  const { showModal } = useModalManager()
  return useCallback((options: AlertModalProps) => {
    showModal({
      component: Alert,
      props: options as AlertModalProps,
    })
  }, [showModal])
}
