import { ChangeEvent, useCallback, useState, FocusEvent } from 'react';
import { Dictionary } from '@/shared-types';

type returnType = [ Dictionary, (e: ChangeEvent<HTMLInputElement>) => void, (e: FocusEvent<HTMLInputElement>) => void, (e: FocusEvent<HTMLInputElement>) => void, boolean];

const useInput = (initialValue: Dictionary) : returnType => {
  const [form, setForm] = useState(initialValue);
  const [isFocused, setInputFocus] = useState<boolean>(false)
  
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const {name, value} = event.target;
    setForm((form: Dictionary) => ({ ...form, [name]: value }));
    },[]);
  
  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setInputFocus(true)
  },[])
  
  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setInputFocus(false)
  },[])
  
  return [form, handleChange, handleFocus, handleBlur, isFocused]
};

export { useInput }
