import Axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {storage} from "@/utils";
import {store} from "@/store";
import {setAccessToken} from "@/store/app";

export type RequestConfig = Omit<AxiosRequestConfig, 'headers'> & { headers: Record<string, string> }
export type Response<ResData> = AxiosResponse<ResData>
export interface CoreConfig {
    onRequest: (config: RequestConfig, axios: AxiosInstance) => Promise<RequestConfig> | RequestConfig;
}

export interface AxiosConfig extends AxiosRequestConfig {
    hostUrl?: string;
    basePath?: string;
}

type ClientConfig = CoreConfig & AxiosConfig

export function isAxiosError(error: any): error is AxiosError {
    return Boolean((error as AxiosError).isAxiosError)
}

function initAxiosClient({onRequest = async (config) => config,...envConfig}: ClientConfig) {
    initAxios(envConfig)

    axiosClient.interceptors.request.use((config) => {
        config.headers = config.headers || {}
        // config.headers['Req-Time'] = moment().format('yyyyMMddHHmmss')
        return onRequest(config as RequestConfig, axiosClient)
    })

    axiosClient.interceptors.response.use((response) => {
        // const { status, data } = response
        return response
    }, async (error) => {
        if (error.response?.status === 401) {
            store.dispatch(setAccessToken(''))
        }
        return Promise.reject(error)
    })
}

function initAxios({
                       hostUrl = '',
                       basePath = '',
                       ...restConfig
                   }: Omit<AxiosConfig, 'url' | 'baseURL'>) {
    Object.assign(axiosClient.defaults, {
        baseURL: `${hostUrl}${basePath}`,
        timeout: 600000,
        ...restConfig,
    })
}

const axiosClient = Axios.create()
initAxiosClient({
    hostUrl: process.env.REACT_APP_API_HOST_URL,
    basePath: '/',
    onRequest(config) {
        const accessToken = storage.getAccessToken()
        if(accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`
        else delete config.headers['Authorization']
        return config
    },
})

export { axiosClient }
