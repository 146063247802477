import React, {createContext, FC, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid'
import {ToastProps} from "@/components/toast/types";

interface Props extends ToastProps{
    id: string;
}

interface ToastContextProps {
    queue: Props[];
    addToast: (props: {message: string}) => void;
}

export const ToastContext = createContext<ToastContextProps>({
    queue: [],
    addToast: () => {},
});

export const ToastProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
    const [queue, setQueue] = useState<Props[]>([])
    const [targetId, setTargetId] = useState('')

    const addToast = ({message}: {message: string}) => {
        const id = uuidv4()
        setQueue((prevQueue) => {
            const foundIndex = prevQueue.findIndex((item) => item.message === message)
            return foundIndex === -1 ? [...prevQueue, { message, id, active: !prevQueue.length }] : prevQueue
        })
    }

    const removeAnimation = ({id}: {id: string}) => {
        setQueue((prevQueue) => {
            return prevQueue.map((item) => {
                item.id === id && (item.active = false)
                return item
            })
        })
    }

    const removeToast = ({id}: {id: string}) => {
        setQueue((prevQueue) => {
            const filterQueue = prevQueue.filter(toast => toast.id !== id)
            return filterQueue.map((item, index) => {
                index === 0 && (item.active = true)
                return item
            })
        })
    }

    useEffect(() => {
        if(queue.length > 0 && targetId !== queue[0].id){
            const toast = queue[0]
            const id = toast.id
            setTimeout(() => removeAnimation({id}), 3000)
            setTimeout(() => removeToast({id}), 3100)
            setTargetId(id)
        }
    }, [queue, targetId])


    const toastContextValue: ToastContextProps = {
        queue,
        addToast,
    };

    return (
        <ToastContext.Provider value={toastContextValue}>
            {children}
        </ToastContext.Provider>
    );
};