import React, {useRef} from "react";
import {ToastProps} from "./types";
import {CSSTransition} from "react-transition-group";
import cx from 'classnames';

export function Toast({ message, active }: ToastProps) {
    const ref = useRef(null)
    return (
        <CSSTransition nodeRef={ref} in={active} timeout={0} classNames="fade" unmountOnExit={false}>
            <div ref={ref} className={cx('toast_message', {active: active})}>{message}</div>
        </CSSTransition>
    )
}