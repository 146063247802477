import React, { FC } from 'react'
import { Back } from '../button'

export interface HeaderProps {
  title?: string
}

const Header: FC<HeaderProps> = props => {
  return (
    <header className="header">
      <Back />
      <h1 className="header_title">{props.title}</h1>
    </header>
  )
}

export { Header }