import Axios from 'axios'
import {useEffect, useState} from 'react'
import {getHistoryByStoreId, StoreHistoryData} from "@/axios";
import {useShowGlobalLoading} from "@/components/loading/hooks";

interface SearchParams {
    page: number;
    reloadCnt: number;
}

export function useFetchHistoryByStoreId(params: SearchParams) {
    const {page, reloadCnt} = params
    const [ready, setReady] = useState(false)
    const [list, setList] = useState<StoreHistoryData[]>([])
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getHistoryByStoreId({page}, { cancelToken: canceler.token })
                .then(({data}) => {
                    // console.log(1)
                    setList(data.message.rows)
                    setReady(true)
                })
                .catch(() => {
                    // console.log(2)
                    setList([])
                    setReady(true)
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, page, reloadCnt])

    return {list, setList, ready, setReady}
}