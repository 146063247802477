import Axios, {AxiosError, CancelTokenSource} from 'axios'
import React, {useCallback, useState} from 'react'
import {storeLogin, LoginParams, storeTokenValidate} from "@/axios";
import {store} from "@/store";
import {setAccessToken} from "@/store/app";
import {useAlertModal} from "@/components";
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {setLoginInfo} from "@/store/login-info";

interface ResponseData {
    access_token: string;
}

export function useStoreLogin() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const alertModal = useAlertModal()
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: LoginParams) => {
        if(!params.id){
            /*
            * 오류 방어용 코드
            * 상점 코드를 입력하지 않았을 경우 로그인 버튼이 활성화 되지 않기 때문에 storeId 없이 호출되지 않는 hook
            * */
            alertModal({
                message: <>상점 코드를 입력해주세요.</>,
                okText: '확인',
                okTimeout: -1,
            })
            return Promise.reject()
        }else{
            if(prevCanceler) prevCanceler.cancel()
            const canceler = Axios.CancelToken.source()
            setCanceler(canceler)

            return new Promise<ResponseData>((resolve, reject) => {
                showGlobalLoading(
                    storeLogin(params, { cancelToken: canceler.token })
                        .then( async ({data: {message}}) => {
                            console.log(message)
                            const {access_token} = message
                            console.log(access_token)
                            if(access_token){
                                store.dispatch(setAccessToken(access_token))
                                try{
                                    const response = await storeTokenValidate()
                                    const {status, data} = response
                                    if(status === 200){
                                        store.dispatch(setLoginInfo(data.message))
                                        resolve({access_token})
                                    }
                                }catch(e){
                                    alertModal({
                                        message: <>상점 정보 조회에 실패했습니다.<br/>다시 시도해주세요.</>,
                                        okText: '확인',
                                        okTimeout: -1,
                                    })
                                    reject()
                                }
                            }else{
                                alertModal({
                                    message: <>등록되지 않은 상점 코드 입니다.</>,
                                    okText: '확인',
                                    okTimeout: -1,
                                })
                                reject()
                            }
                        })
                        .catch((err: AxiosError) => {
                            alertModal({
                                message: <>등록되지 않은 상점 코드 입니다.</>,
                                okText: '확인',
                                okTimeout: -1,
                            })
                            reject()
                        })
                )
            })
        }

    }, [prevCanceler, alertModal, showGlobalLoading])
}