import Axios, {AxiosError, CancelTokenSource} from 'axios'
import React, {useCallback, useState} from 'react'
import {exitRequest} from "@/axios";
import {useAlertModal} from "@/components";
import {useShowGlobalLoading} from "@/components/loading/hooks";

interface ResponseData {
    id: number;
}

export function useExitRequest() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const alertModal = useAlertModal()
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback(({id, plateNumber}:{
        id: number;
        plateNumber: string;
    }) => {
        if(!id){
            /*
            * 오류 방어용 코드
            * 차량번호를 선택하지 않았을 경우 출차요청 버튼이 활성화 되지 않기 때문에 id 없이 호출되지 않는 hook
            * */
            alertModal({
                message: <>차량번호를 선택해주세요.</>,
                okText: '확인',
                okTimeout: -1,
            })
            return Promise.reject()
        }else{
            if(prevCanceler) prevCanceler.cancel()
            const canceler = Axios.CancelToken.source()
            setCanceler(canceler)

            return new Promise<ResponseData>((resolve, reject) => {
                showGlobalLoading(
                    exitRequest({id}, { cancelToken: canceler.token })
                        .then( () => {
                            // alarm({valetParkingId: id, content: `출차요청이 도착했습니다. 차량번호: ${plateNumber}`})
                            resolve({id})
                        })
                        .catch((err: AxiosError) => {
                            if(err.response?.data?.message){
                                alertModal({
                                    message: err.response.data.message,
                                    okText: '확인',
                                    okTimeout: -1,
                                    onOkClick: () => setTimeout(() => reject(err.response?.status), 100),
                                })
                            }else{
                                reject()
                            }
                        })
                )
            })
        }

    }, [prevCanceler, alertModal, showGlobalLoading])
}