import { createBrowserHistory } from 'history'
import React, {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import App from './App'
import { BrowserRouter } from './providers/browser-router'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import './styles/index.scss'
import {storeTokenValidate} from "@/axios";
import {setLoginInfo} from "@/store/login-info";
import {storage} from "@/utils";
import {setAccessToken} from "@/store/app";
import { ToastProvider } from '@/components';

const isPrd = process.env.REACT_APP_ENV === 'prd'
const DevTools = lazy(() => import('./dev-tools/ValetDevTools'))
const history = createBrowserHistory()

async function start() {
    const access_token = storage.getAccessToken()
    if(access_token){
        try{
            const response = await storeTokenValidate()
            const {status, data} = response
            if(status === 200){
                store.dispatch(setAccessToken(access_token))
                store.dispatch(setLoginInfo(data.message))
            }
        }catch(e){}
    }

    const rootNode = document.getElementById('root');
    if (!rootNode) throw new Error('Failed to find the root element');
    ReactDOM.createRoot(rootNode).render(
        <React.StrictMode>
            <Provider store={store}>
                <ToastProvider>
                    <BrowserRouter history={history}>
                        <App/>
                        {!isPrd && <Suspense fallback=""><DevTools/></Suspense>}
                    </BrowserRouter>
                </ToastProvider>
            </Provider>
        </React.StrictMode>,
    );
}

start()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
