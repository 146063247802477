import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/axios";

/**
 * 입차요청 API
 * @param params
 * @param config
 */
export function entryRequest(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const { plateNumber } = params
    const { params: configParams, ...restConfig } = config
    return axiosClient.post<ResponseData>('/api/valet-parking/entryRequest',
        {
            plateNumber,
            ...configParams,
        },
        {
            ...restConfig,
        },
    )
}

/**
 * plateNumber:     차량 번호
 */
interface Params {
    plateNumber: string;
}

/**
 * id:              store id, PK
 * plateNumber:     차량 번호
 * parkingStatus:   주차 상태 (0: 입차 완료, 1: 출차 완료, 2: 입차요청, 3: 출차요청, 4: 입차접수, 5: 출차접수, 6:입차진행
 */
interface ResponseData {
    message: {
        id: number;
        plateNumber: string;
        parkingStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
    },
}

