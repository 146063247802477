import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '@/store'

type AppState = typeof initialState

interface Actions extends SliceCaseReducers<AppState> {
  setAccessToken(state: AppState, action: PayloadAction<string>): void;
  setGlobalLoading(state: AppState, action: PayloadAction<boolean>): void;
  setPinCode(state: AppState, action: PayloadAction<string>): void;
  setIsMobile(state: AppState, action: PayloadAction<boolean>): void;
}

const initialState = {
  accessToken: '',
  globalLoading: false,
  pinCode: '',
  isMobile: false,
}

export const appSlice = createSlice<AppState, Actions>({
  name: 'app',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      const { payload } = action
      state.accessToken = payload
    },
    setGlobalLoading(state, action) {
      const { payload } = action
      state.globalLoading = payload
    },
    setPinCode(state, action) {
      const { payload } = action
      state.pinCode = payload
    },
    setIsMobile(state, action) {
      const { payload } = action
      state.isMobile = payload
    },
  },
})

export const {
  setAccessToken,
  setGlobalLoading,
  setPinCode,
  setIsMobile,
} = appSlice.actions

export const selectAppAccessToken = (state: RootState) => state.app.accessToken
export const selectAppGlobalLoading = (state: RootState) => state.app.globalLoading
export const selectAppPinCode = (state: RootState) => state.app.pinCode
export const selectAppIsMobile = (state: RootState) => state.app.isMobile