import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit'
import { useDispatch, useStore } from 'react-redux'
import {appSlice, setAccessToken} from './app'
import {storage} from "@/utils";
import {loginInfoSlice} from "@/store/login-info";

const rootReducer = combineReducers({
    app: appSlice.reducer,
    loginInfo: loginInfoSlice.reducer,
})

const middleware: Middleware<{}, RootState> = (store) => {
    return (next) => {
        return (action) => {
            switch (action.type) {
                case setAccessToken.type:
                    if(action.payload) storage.setAccessToken(action.payload)
                    else storage.removeAccessToken()
                    break
            }
            return next(action)
        }
    }
}

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppStore = () => useStore<RootState>()
export { store }
