import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/axios";

/**
 * 토큰 검증 및 사용자 정보 조회 API
 * @param config
 */
export function storeTokenValidate(config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/auth/store-token-validate', {
        ...restConfig,
        params: {
            ...configParams
        }
    })
}

interface ResponseData {
    message: LoginInfoData;
}

interface LoginInfoZoneData {
    id: number;
}
export interface LoginInfoData {
    id: number;
    name: string;
    contact: string;
    address: string;
    defaultPrice: number;
    defaultPriceUnitTime: number;
    hasExtraCharge: boolean;
    extraCharge: number;
    extraChargeUnitTime: number;
    eventPrice: number;
    isEvent: boolean;
    price: number;
    isActive: boolean;
    isDeleted: boolean;
    createdAt: string; // 2022-08-03T03:01:21.111Z
    updatedAt: string; // 2022-08-03T03:01:21.111Z
    zoneId: number;
    zone: LoginInfoZoneData;
}
