import { useCallback } from 'react'
import { Confirm, ConfirmModalProps } from '../Confirm'
import { useModalManager } from './use-modal-manager'

export function useConfirmModal() {
  const { showModal } = useModalManager()
  return useCallback((options: ConfirmModalProps) => {
    showModal({
      component: Confirm,
      props: options as ConfirmModalProps,
    })
  }, [showModal])
}
