import Axios, {AxiosError, CancelTokenSource} from 'axios'
import React, {useCallback, useState} from 'react'
import {entryRequest} from "@/axios";
import {useAlertModal} from "@/components";
import {useShowGlobalLoading} from "@/components/loading/hooks";

interface ResponseData {
    id: number;
    plateNumber: string;
    parkingStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}

export function useEntryRequest() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const alertModal = useAlertModal()
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback(({plateNumber}:{plateNumber: string}) => {
        if(!plateNumber){
            /*
            * 오류 방어용 코드
            * 차량번호를 입력하지 않았을 경우 입차 버튼이 활성화 되지 않기 때문에 plateNumber 없이 호출되지 않는 hook
            * */
            alertModal({
                message: <>차량번호를 입력해주세요.</>,
                okText: '확인',
                okTimeout: -1,
            })
            return Promise.reject()
        }else{
            if(prevCanceler) prevCanceler.cancel()
            const canceler = Axios.CancelToken.source()
            setCanceler(canceler)

            return new Promise<ResponseData>((resolve, reject) => {
                showGlobalLoading(
                    entryRequest({plateNumber}, { cancelToken: canceler.token })
                        .then( ({data: {message}}) => {
                            const {id, plateNumber, parkingStatus} = message
                            // alarm({valetParkingId: id, content: `입차요청이 도착했습니다. 차량번호: ${plateNumber}`})
                            resolve({id, parkingStatus, plateNumber})

                        })
                        .catch((err: AxiosError) => {
                            if(err.response?.data?.message){
                                alertModal({
                                    message: err.response.data.message,
                                    okText: '확인',
                                    okTimeout: -1,
                                    onOkClick: () => setTimeout(() => reject(err.response?.status), 100),
                                })
                            }else{
                                reject()
                            }
                        })
                )
            })
        }

    }, [prevCanceler, alertModal, showGlobalLoading])
}