import React, { ReactNode } from 'react'
import { useModalManager } from './hooks'
import { ModalCommonProps } from './types'

export interface ConfirmModalProps extends ModalCommonProps {
  message: ReactNode;
  onOk: () => void;
  onCancel?: () => void;
}

function Confirm(props: ConfirmModalProps) {
  const { message, onOk, onCancel } = props
  const { closeModal } = useModalManager()

  return (
    <div className="modal_wrap">
      <div className="modal_container">
        <div className="modal_content">
          <div className="modal_alert">
            <h1 className="modal_alert_title">{message}</h1>
          </div>
        </div>

        <footer className="modal_footer">
          <button type="button" className="btn_default" onClick={() => {
            closeModal()
            onCancel && onCancel()
          }}>아니요</button>
          <button type="button" className="btn_primary" onClick={() => {
            closeModal()
            onOk()
          }}>예</button>
        </footer>
      </div>
    </div>
  )
}

export { Confirm }
