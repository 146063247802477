import React, {useCallback} from 'react'
import { useBack } from './hooks'
import { Button } from './Button';

interface Props {
  onBackClick?: () => void
}

function Back(props: Props) {
  const { onBackClick } = props
  const goBack = useBack()
  const onClick = useCallback(() => {
    if(onBackClick) onBackClick()
    goBack()
  }, [goBack, onBackClick])

  return (
    <Button group="image" variant="back" innerWrap={false} onClick={onClick}>이전</Button>
  )
}

export { Back }
