import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { selectAppIsMobile } from '@/store/app';

interface Props {
  code: string;
}

function ViewCode(props: Props) {
  const isMobile = useSelector(selectAppIsMobile)
  const code = useMemo(() => props.code.split(''), [props.code]);
  
  return (
    <div className="pincode_view_wrap">
      {!isMobile && <h2 className="pincode_view_title">차량번호 4자리를 입력해주세요.</h2>}
      <div className="pincode_view_content">
        <div className="code_box">{code[0]}</div>
        <div className="code_box">{code[1]}</div>
        <div className="code_box">{code[2]}</div>
        <div className="code_box">{code[3]}</div>
      </div>
    </div>
  )
}

export { ViewCode };
