import { useEffect, useRef } from 'react';

type Callback = () => void

export const useLazyScroll = (callback: Callback) => {
  const scrollRef = useRef<Callback | null>(null)

  useEffect(() => {
    scrollRef.current = callback
  }, [callback])

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const handleScroll = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        scrollRef.current && scrollRef.current()
      }, 300)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
}