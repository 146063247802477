import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import { useModalManager } from './hooks'
import { ModalCommonProps } from './types'

export interface AlertModalProps extends ModalCommonProps {
  message: ReactNode;
  description?: ReactNode;
  okText: string;
  okTimeout?: number;
  onOkClick?: () => void;
}

function Alert(props: AlertModalProps) {
  const { message, description, okText, okTimeout = 8, onOkClick } = props
  const [timeout, countDown] = useState(okTimeout)
  const useTimer = useMemo(() => okTimeout > -1, [okTimeout])
  const { closeModal } = useModalManager()

  const handleOkClick = useCallback(() => {
    closeModal()
    if(onOkClick) onOkClick()
  }, [closeModal, onOkClick])

  useEffect(() => {
    if (timeout > -1) {
      const timerId = setTimeout(() => countDown(timeout - 1), 1000)
      return () => clearTimeout(timerId)
    } else if (useTimer) {
      closeModal()
    }
  }, [timeout, useTimer, closeModal])

  return (
    <div className="modal_wrap">
      <div className="modal_container">
        <div className="modal_content">
          <div className="modal_alert">
            <h1 className="modal_alert_title">{message}</h1>
            {description && <p className="modal_alert_msg">{description}</p>}
          </div>
        </div>

        <footer className="modal_footer">
          <button type="button" className="btn_primary" onClick={() => handleOkClick()}>
            {okText} {timeout > -1 && <em className="timer">{timeout}</em>}
          </button>
        </footer>
      </div>
    </div>
  )
}

export { Alert }
