import { RefObject, useCallback } from 'react'
import { useWebViewConfig } from '../providers'

function useCopyNodeText<E extends HTMLElement>(refNode?: RefObject<E> | null) {
  const webViewConfig = useWebViewConfig()
  return useCallback(() => {
    if (!refNode?.current) {
      return
    }
    let text
    if (refNode.current.tagName === 'INPUT' || refNode.current.tagName === 'TEXTAREA') {
      text = (refNode.current as any).value
    } else {
      text = refNode.current.textContent || ''
    }

    if (webViewConfig?.onCopyText) {
      webViewConfig.onCopyText(text)
      webViewConfig?.onToastMessage?.('클립보드에 저장했습니다.')
    } else {
      const textNode = document.createElement('textarea')
      textNode.value = text
      textNode.style.position = 'absolute'
      textNode.style.width = '0'
      textNode.style.height = '0'
      textNode.style.visibility = 'none'
      document.body.appendChild(textNode)

      // for safari
      const range = document.createRange()
      range.selectNode(textNode)
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(range)
      textNode.setSelectionRange(0, textNode.value.length)

      // for others
      textNode.select()

      const result = document.execCommand('copy')
      document.body.removeChild(textNode)

      webViewConfig?.onToastMessage?.(result ? '클립보드에 저장했습니다.' : '클립보드에 저장이 실패했습니다.')
    }
  }, [refNode, webViewConfig])
}

export { useCopyNodeText }
