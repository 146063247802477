import Axios, {AxiosError, CancelTokenSource} from 'axios'
import {useCallback, useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {getExitByPlateNumber, StoreExitData} from "@/axios";
import {useAlertModal} from "@/components";

interface SearchParams {
    plateNumber: string;
}

interface ResponseData {
    list: StoreExitData[];
}


export function useFetchExitByPlateNumber(params: SearchParams) {
    const {plateNumber} = params
    const [list, setList] = useState<StoreExitData[]>([])
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        if(plateNumber){
            const canceler = Axios.CancelToken.source()
            showGlobalLoading(
                getExitByPlateNumber({plateNumber}, { cancelToken: canceler.token })
                    .then(({data}) => {
                        // console.log(1)
                        setList(data.message)
                    })
                    .catch(() => {
                        // console.log(2)
                        setList([])
                    })
            )
            return () => {
                canceler.cancel()
            }
        }
    }, [showGlobalLoading, plateNumber])

    return {list}
}

export function useGetExitByPlateNumber() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const alertModal = useAlertModal()
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: SearchParams) => {
        const {plateNumber} = params
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                getExitByPlateNumber({plateNumber}, { cancelToken: canceler.token })
                    .then( ({data: {message}}) => {
                        resolve({list: message})
                    })
                    .catch((err: AxiosError) => {
                        if(err.response?.data?.message){
                            alertModal({
                                message: err.response.data.message,
                                okText: '확인',
                                okTimeout: -1,
                                onOkClick: () => setTimeout(() => reject(err.response?.status), 100),
                            })
                        }else{
                            reject()
                        }
                    })
            )
        })
    }, [prevCanceler, alertModal, showGlobalLoading])
}