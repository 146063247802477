import React, { SyntheticEvent } from 'react'
import { Button } from '../button';

interface Props {
  handleClick: (e:SyntheticEvent)=> void;
  handleCodeDelete: ()=> void;
}

function Keypad(props: Props) {
  const { handleClick, handleCodeDelete } = props;
  
  return (
    <div className="keypad_button_group">
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>1</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>2</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>3</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>4</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>5</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>6</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>7</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>8</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>9</Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick} disabled> </Button>
      <Button group="plain" variant="digit" innerWrap={false} onClick={handleClick}>0</Button>
      <Button group="image" variant="delete" innerWrap={false} onClick={handleCodeDelete}> </Button>
    </div>
  )
}

export { Keypad };
